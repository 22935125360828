import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            defaultDescription {
              en
              ru
            }
            keywords {
              en
              ru
            }
            languages {
              defaultLangKey
              langs
            }
            siteUrl
            title
          }
        }
      }
    `
  )
  return site.siteMetadata
}
